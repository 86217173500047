import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { deleteItem, getCartDetail, updateCartItem } from '../../../app/features/cart/cart-api'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import ic_regular from '../../../assets/icons/DetailProductScreen/ic_regular.svg'
import { ReactComponent as Check } from '../../../assets/icons/fontAwaresome/check.svg'
import ic_campaign from '../../../assets/icons/ic_campaign.png'
import ic_campaign_gift from '../../../assets/icons/ic_campaign_gift.png'
import ic_campaign_jp from '../../../assets/icons/ic_campaign_jp.png'
import icon_gift_jp from '../../../assets/icons/ic_campaign_gift_jp.png'
import { formatDiscount } from '../../../helpers/formatNumber'
import { formatPriceVND, handleItemPrice } from '../../../helpers/formatPrice'
import { debounce } from '../../../helpers/ultis'
import { CartItemType } from '../../../models/cart'
import QuantitySelect from '../../common/QuantitySelect'
import { selectIsLoading } from '../store/slice'
import FastCartGiftItem from './fastCartGiftItem'
import RenderChangeBuyType from './renderChangeBuyType'
import { GIFT_TYPE, GREAT_DEAL_TYPE } from '../../../constant'
import { ROUTER } from '../../../router/config'
interface props {
  //check sp dinh ki
  allow_frequency?: boolean
  item: CartItemType
}
const lang =
  localStorage.getItem('languageOmiPharma') || (navigator.languages ? navigator.languages[0] : navigator.language)

function FastCartCheckoutItem(props: props) {
  const { item } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(item.quantity)
  const isLoading = useAppSelector(selectIsLoading)

  const handleMainItem = (item: CartItemType) => {
    if (!item.is_combo) {
      return item
    }
    const mainItem = item.items.find((i) => i.is_main === 1)
    return mainItem
  }

  const subItem = item.items?.filter((i) => i.is_main === 0) || []
  const originPrice = handleItemPrice(item, 'price_origin')
  const price = handleItemPrice(item, 'price')

  const mainItem = handleMainItem(item)
  const itemImg = mainItem?.images?.[0]

  const discountPercent = mainItem?.discount_percent ?? 0

  const handleSelectItem = () => {
    dispatch(
      updateCartItem({
        cart_item_id: item.cart_item_id,
        selected: item.selected === 1 ? 0 : 1,
      }),
    ).then((res: any) => {
      if (res?.payload?.code === 200) {
        dispatch(getCartDetail())
      }
    })
  }

  const handleDeleteItem = () => {
    dispatch(deleteItem(item.cart_item_id.toString())).then((res: any) => {
      if (res?.payload?.code === 200) {
        dispatch(getCartDetail())
      }
    })
  }

  const handleChangeQuantity = (quantity: number) => {
    setQuantity(quantity)
    changeQuantity(quantity)
  }
  const changeQuantity = useCallback(
    debounce((quantity: number) => {
      dispatch(
        updateCartItem({
          cart_item_id: item.cart_item_id,
          quantity: quantity,
        }),
      ).then((res: any) => {
        if (res?.payload?.code === 200) {
          dispatch(getCartDetail())
        }
      })
    }, 500),
    [],
  )

  useEffect(() => {
    if (item) {
      setQuantity(item.quantity)
    }
  }, [item])

  const itemCombo = item?.items?.filter((e) => e.is_main)

  return (
    <div>
      <div className='fast-cart-checkout-item'>
        <div onClick={handleSelectItem} className='fast-cart-checkout-item__btn-check'>
          <div className={item.selected === 1 ? 'check-radio active' : 'check-radio'}>
            <Check />
          </div>
        </div>
        <Link to={ROUTER.PRODUCT_DETAIL + mainItem?.slug} className='fast-cart-checkout-item__image-box'>
          <img src={itemImg} alt='product-thumb' />
        </Link>
        <div className='fast-cart-checkout-item__content-box'>
          <div className='item-content-box-left'>
            {item.allow_frequency === 1 ? (
              <div className='item-content-box-left__regular-label'>
                <img src={ic_regular} alt='ic-regular' />
                <p>{t('fastCart.buyPeriodically')}</p>
              </div>
            ) : null}
            <div className='item-content-box-left__campaign-tag'>
              {item.type === GREAT_DEAL_TYPE && (
                <img src={lang === 'jp' ? ic_campaign_jp : ic_campaign} alt='campaign-tag' />
              )}
              {item.type === GIFT_TYPE && (
                <img src={lang === 'jp' ? icon_gift_jp : ic_campaign_gift} alt='campaign-tag' />
              )}
            </div>
            <Link to={ROUTER.PRODUCT_DETAIL + mainItem?.slug} className='item-content-box-left__product-name'>
              {item.is_combo ? itemCombo?.[0].name : item.name}
            </Link>
            {item?.variant_name && <p className='item-content-box-left__variant-name'>{item?.variant_name}</p>}
            <div className='item-content-box-left__price-box'>
              <span className='item-content-box-left__price-box__price-active'>
                {item.is_combo ? formatPriceVND(itemCombo?.[0].price) : formatPriceVND(price)}
              </span>
              {discountPercent > 0 && (
                <>
                  <span className='item-content-box-left__price-box__discount'>{formatDiscount(discountPercent)}</span>
                  <span className='item-content-box-left__price-box__price-origin'>{formatPriceVND(originPrice)}</span>
                </>
              )}
            </div>
            {item.is_frequency === 1 ? (
              <div className='item-content-box-left__cycle-box'>
                <p>{t('fastCart.deliveryCycle')}</p>
                <span className='item-content-box-left__cycle-box_value'>{item?.frequency}</span>
              </div>
            ) : null}
            {item.is_frequency === 1 ? (
              <p className='item-content-box-left__time-box'>
                {t('fastCart.expectedDelivery')}
                <span>{item.next_time_order}</span>
              </p>
            ) : null}
          </div>
          <div className='item-content-box-right'>
            <p className='item-content-box-right__total-price'>{formatPriceVND(price * quantity)}</p>
            <div className='item-content-box-right__quantity-wrap'>
              <QuantitySelect disable={isLoading} quantity={quantity} changeQuantity={handleChangeQuantity} />
            </div>
            {item.is_frequency === 1 ? (
              <p className='item-content-box-right__regular-note'>
                {t('fastCart.TheProducHasBeenDiscountedRegularPurchases')}
              </p>
            ) : null}
            <div className='item-content-box-right__btn-edit'>
              {/* <button>{t('fastCart.buyLater')}</button> */}
              <button onClick={handleDeleteItem}>{t('fastCart.delete')}</button>
            </div>
            <div className='item-content-box-right__buy-type'>
              {item.allow_frequency === 1 && (
                <RenderChangeBuyType
                  value={item.frequency}
                  cart_item_id={item.cart_item_id}
                  allow_frequency={item.is_frequency === 1 ? true : false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {item.type === GREAT_DEAL_TYPE || item.type === GIFT_TYPE
        ? subItem.map((i, index) => <FastCartGiftItem checked={true} key={index} item={i} type={item.type} />)
        : null}
    </div>
  )
}

export default FastCartCheckoutItem
