import $ from 'jquery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getCartDetail } from '../../app/features/cart/cart-api'
import { createOrder } from '../../app/features/order/order-api'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { authStore } from '../../app/use-selector'
import { ReactComponent as Times } from '../../assets/icons/fontAwaresome/times.svg'
import '../../assets/scss/fastCart.scss'
import { toastSuccessErr } from '../../helpers/toast'
import FastCartContentInfo from './fastCartContentInfo'
import FooterFastCart from './footerFastCart'
import { changeListProductCollapse, selectCartData, selectIsLoading } from './store/slice'
import ic_cart from '../../assets/icons/CartScreen/cart_icon.svg'
import LoadingOverlay from '../LoadingOverlay'
import { ROUTER } from '../../router/config'
import { useLazyGetCreateOrderDetailQuery } from '../../app/features/order'
import { changeStateData } from '../../pages/checkoutScreen/store/slice'
import queryString from 'query-string'
function FastCartModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [showPickerVoucher, setShowPickerVoucher] = useState(false)
  const [showFullProduct, setShowFullProduct] = useState(false)
  const cartData = useAppSelector(selectCartData)
  const isLoading = useAppSelector(selectIsLoading)
  const auth = useAppSelector(authStore)
  const [usedPoints, setUsedPoints] = useState(0)
  const [discount, setDiscount] = useState(0)
  const totalPriceTemp = cartData?.amount || 0
  const addedPoint = Math.floor(totalPriceTemp / 1000)
  const omiPoint = auth.user ? auth.user.points : 0
  const totalPrice = totalPriceTemp ? totalPriceTemp - discount - usedPoints : 0
  const totalQuantity =
    totalPrice && cartData?.items
      ? cartData.items.reduce((total, item) => {
          let result = total
          if (item.selected) {
            result = item.quantity + total
          }
          return result
        }, 0)
      : 0
  const paramsUrl = queryString.parse(window.location.search) as any
  const [getOrderDetail, { data: orderDetail }] = useLazyGetCreateOrderDetailQuery({})

  const cartNumber = cartData?.items
    ? cartData.items.reduce((total, item) => {
        return item.quantity + total
      }, 0)
    : 0

  useEffect(() => {
    if (cartData) {
      const cartItems = cartData.items
      const listProductCollapse = [] as any
      cartItems.forEach((item) => {
        if (!item.is_combo) {
          listProductCollapse.push(item)
        }
        if (item.is_combo && !item.deal_type) {
          listProductCollapse.push({
            ...item.items?.[0],
            name: item.name,
            quantity: item?.quantity || 1,
            price: item.price,
            cart_item_id: item.cart_item_id,
          })
        }
        if (item.is_combo && item.deal_type) {
          const mainItem = item.items.find((i) => i.is_main === 1)
          listProductCollapse.push({
            ...mainItem,
            quantity: item?.quantity || 1,
            price: item.price,
            cart_item_id: item.cart_item_id,
          })
        }
      })
      dispatch(changeListProductCollapse(listProductCollapse))
      const discount = cartData?.discount || 0
      setDiscount(discount)
    }
  }, [cartData])

  const changeShowVou = (value: boolean) => {
    setShowPickerVoucher(value)
  }
  const onCheckout = () => {
    if (!totalPriceTemp) {
      toastSuccessErr({ type: 'error', message: t('fastCart.noChooseItem') })
      return
    }
    setLoading(true)
    dispatch(createOrder({ is_buy_now: 0, use_omi_points: usedPoints }))
      .then((res: any) => {
        if (res?.payload?.code === 200) {
          $('#modalFastAccessCart').hide()
          $('body').removeClass('modal-open').css({ overflow: 'auto', 'padding-right': 0 })
          $('.modal-backdrop').remove()
          const orderId = res?.payload?.data?.id
          if (paramsUrl.order_id) {
            getOrderDetail(orderId).then((orderDetail) => {
              dispatch(changeStateData(orderDetail?.data))
            })
          }
          navigate(`/user/create-order?order_id=${orderId}`, { replace: true })
        } else {
          toastSuccessErr({ type: 'error', message: res?.payload?.message })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClickShopping = () => {
    $('#modalFastAccessCart').hide()
    $('body').removeClass('modal-open').css({ overflow: 'auto', 'padding-right': 0 })
    $('.modal-backdrop').remove()
    navigate(ROUTER.HOME_PAGE)
  }

  useEffect(() => {
    dispatch(getCartDetail())
  }, [])

  return (
    <div className='modal-fast-cart'>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        id='modalFastAccessCart'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-xl'>
          <div className='modal-content modal-cart-content'>
            <div className='modal-cart-content__header'>
              <h3>{cartNumber > 0 ? t('fastCart.cartWithNumber', { number: cartNumber }) : t('fastCart.cart')}</h3>
              <button type='button' data-bs-dismiss='modal' aria-label='Close'>
                <Times />
              </button>
            </div>
            {cartNumber > 0 ? (
              <>
                {/* <div className='modal-cart-content__transfer'>
              <div className='modal-cart-content__transfer__milestone'>
                <RenderMilestone />
                <div className='milestone-gift-box'>
                  <img src={ic_transfer} alt='icon-transfer' />
                  <p>{t('fastCart.greatSupportShip')}</p>
                </div>
              </div>
              <div className='modal-cart-content__transfer__info'>
                <h5>{t('fastCart.deliveryAddress')}</h5>
                <div className='transfer-row-info'>
                  <p className='transfer-row-info__label'>{t('fastCart.deliveryFrom')}</p>
                  <p className='transfer-row-info__text'>OmiPharma GreenStar - Bắc Từ Liêm - Hà Nội</p>
                </div>
                <div className='transfer-row-info'>
                  <p className='transfer-row-info__label'>{t('fastCart.deliveryFrom')}</p>
                  <FastCartAddressPicker />
                </div>
              </div>
            </div> */}
                <FastCartContentInfo
                  changeShowVou={(v) => changeShowVou(v)}
                  setShowFullProduct={(v: boolean) => setShowFullProduct(v)}
                  showFullProduct={showFullProduct}
                  updateUsePoint={(usedPoint: any) => setUsedPoints(usedPoint)}
                  showPickerVoucher={showPickerVoucher}
                  addedPoint={addedPoint}
                  usedPoints={usedPoints}
                  userInfo={auth.user}
                  omiPoint={omiPoint}
                  totalPriceTemp={totalPriceTemp}
                  discount={discount}
                  cartData={cartData}
                  totalQuantity={totalQuantity}
                />
                {!!cartData && (
                  <FooterFastCart
                    onCheckout={onCheckout}
                    cartData={cartData}
                    totalPrice={totalPrice}
                    addedPoint={addedPoint}
                    totalQuantity={totalQuantity}
                    userInfo={auth.user}
                  />
                )}
              </>
            ) : (
              <div className='modal-cart-no-item__container'>
                <div className='modal-cart-no-item__content'>
                  <img src={ic_cart} />
                  <p>{t('fastCart.cartNoProductText')}</p>
                </div>
                <div className='modal-cart-no-item__action'>
                  <button className='modal-cart-no-item__button' onClick={handleClickShopping}>
                    {t('fastCart.shoppingNow')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {(isLoading || loading) && <LoadingOverlay isLoading={isLoading || loading} />}
    </div>
  )
}

export default FastCartModal
